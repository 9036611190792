
					@import './src/sass/variables';
					@import './src/sass/mixins';
				





























































































































































.block-inner {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	padding: $site__padding $site__padding * 2;
}

.answers-wrapper::v-deep {
	flex: 1;

	ol {
		list-style-type: decimal;
	}

	ul ul {
		list-style-type: initial;
	}

	video {
		max-width: 640px;
	}

	p:last-child {
		margin-bottom: 0;
	}
}

.block-text::v-deep {
	position: relative;

	@include question-counter();

	.options::before {
		content: '[options]';
	}
}

.grade {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	input {
		flex: 0 0 80px;
		margin-right: 10px;
		margin-bottom: 0;
		font-size: 1.25rem;

		&[disabled] {
			background-color: $color__semi_dark;
		}
	}

	span {
		white-space: nowrap;
		flex: 1;
		font-size: 1.25rem;
	}
}

.block-change-notice {
	max-width: 100%;
	width: 800px;
	background: rgba($color__red, 0.05);
	padding: 10px;
	border-radius: 4px;
	border: $border;
	margin-bottom: 1.35em;
}
