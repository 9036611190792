
					@import './src/sass/variables';
					@import './src/sass/mixins';
				




































































































































.block-area-wrapper::v-deep {
	display: flex;
	flex-flow: column nowrap;
	flex: 1;
	padding: 0 !important;
}

.block-area {
	counter-reset: question;
}

.block {
	position: relative;
	margin-bottom: 1rem;

	&:not(:first-child) {
		border-top: 1px solid $border_color;
	}
}

.block-type {
	position: absolute;
	bottom: 5px;
	right: 10px;
	text-align: center;
	text-transform: uppercase;
	font-size: 0.75em;
	opacity: 0.5;
	cursor: inherit;
}

.content-footer {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	border-top: $border;
	padding: $site__padding $site__padding * 2 0;

	.logo-wrapper {
		padding: 0;
	}
}
